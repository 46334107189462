import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { toast } from "react-toastify";

export const createLanguage = createAsyncThunk(
  "/create-language",
  async (value) => {
    try {
      let info = await Api.post(`/create-language`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const updateLanguage = createAsyncThunk(
  "/update-language",
  async (value) => {
    try {
      let info = await Api.post(`/update-language`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getLanguage = createAsyncThunk("/language", async (value) => {
  try {
    let info = await Api.get(`/language`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});
