import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Api from "../../Config/ApiConfig";

export const wificreate = createAsyncThunk("wifi-create", async (value) => {
  try {
    let info = await Api.post("wifi-create", value);
    if (info.data.status_code == 200) {
      console.log(info.data.status_code);
      toast.success(info.data.message);
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const wifilist = createAsyncThunk("wifi-list", async (value) => {
  try {
    let info = await Api.post(`wifi-list`, value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const wifidelete = createAsyncThunk("wifi-delete", async (id) => {
  try {
    let info = await Api.delete(`wifi-delete/${id}`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const wifiupdate = createAsyncThunk("wifi-update", async (value) => {
  try {
    let info = await Api.put(`wifi-update`, value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});
