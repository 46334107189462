import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
export const getSubadminlist = createAsyncThunk(
  "getSubadminlist",
  async (value) => {
    try {
      console.log(value, "valuevaluevaluevalue");

      let info = await Api.post(`getSubadminlist`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getDetails = createAsyncThunk("getdetails", async (value) => {
  try {
    let info = await Api.get(`getdetails`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const updateAdminInfo = createAsyncThunk(
  "updateAdminInfo",
  async (value) => {
    try {
      let info = await Api.post(`updateAdminInfo`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (value) => {
    try {
      let info = await Api.post(`updatePassword`, value);
      if (info.data.status_code == 200) {
        toast.success(info.data.message);

        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const userexport = createAsyncThunk("user-export", async (value) => {
  try {
    let info = await Api.post(`user-export`, value);
    return info.data.data;
  } catch (error) {
    toast.error(error.message);
  }
});
