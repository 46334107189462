import { createSlice } from "@reduxjs/toolkit";
import { GetPromotion } from "../Action/PromoCode";

const PromotionSlice = createSlice({
  name: "GetPromotion",
  initialState: {
    promotionlist: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetPromotion.fulfilled, (state, action) => {
      state.promotionlist = action.payload;
    });
  },
});

export default PromotionSlice.reducer;
