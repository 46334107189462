import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { toast } from "react-toastify";

export const totalTransactions = createAsyncThunk(
  "/total-transactions",
  async (value) => {
    try {
      let info = await Api.post(`/total-transactions`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
