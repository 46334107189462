import React from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";

export default function Contentmanagement() {
  return (
    <Layout>
      <div class="dashboard_data_wrap">
        <div class="card-wrapper">
          <div className="card-header">
            <button className="common-btn">
              <NavLink to="/terms">
                Edit
              </NavLink>
            </button>
          </div>
          <div class="content-view-wrap">
            <div className="contentsec">
              <NavLink to="/about">
                <div className="list-1">About Us</div>
              </NavLink>
            </div>
            <div className="contentsec">
              <NavLink to="/policies">
                <div className="list-1">Privacy Policy</div>
              </NavLink>
            </div>
            <div className="contentsec">
              <NavLink to="/terms">
                <div className="list-1">Terms & Conditions</div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
