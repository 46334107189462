import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import usericon from "../assets/images/admin_user_pic.jpg";
import { useDispatch, useSelector } from "react-redux";
import { careseekerlist, updatestaus } from "../Redux/Action/CareSeekerAction";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import DeleteModal from "../Component/DeleteModal";
import Pagination from "../Component/Pagination";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";
import { userexport } from "../Redux/Action/SubAdmin";
import UseFirstLetterCapital from "../CustomHooks/FirstLetterCapital";
import moment from "moment";

export default function Carer() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setShow] = useState(false);
  const [careseekerobject, setcareseekerobject] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      careseekerlist({
        role: ["carer"],
        limit,
        page,
      })
    ).then(() => setLoading(false)); // Stop loader after data is fetched
  }, []);
  const list = useSelector((state) => state.careseeker.careseekerArr);

  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // If the current month is before the birth month, or if it's the same month but the day hasn't passed yet
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return isNaN(age) == true ? "N/A" : age;
  }

  const handleDelete = () => {
    setLoading(true); // Start loader when performing actions
    dispatch(
      updatestaus({
        userId: careseekerobject._id,
        is_deleted: true,
      })
    ).then(() => {
      setShow(false);
      dispatch(
        careseekerlist({
          role: ["carer"],
        })
      ).then(() => setLoading(false)); // Stop loader after actions are done
    });
  };
  const searchdata = () => {
    if (search != undefined) {
      if (search == "") {
        dispatch(
          careseekerlist({
            role: ["carer"],
            limit,
            page,
          })
        );
      } else {
        dispatch(
          careseekerlist({
            role: ["carer"],
            search: search,
            limit,
            page,
          })
        );
      }
    }
  };
  const handlePageClick = (e) => {
    setPage(e.selected);
    setLoading(true);
    dispatch(
      careseekerlist({
        role: ["carer"],
        limit,
        page: e.selected + 1,
      })
    ).then(() => setLoading(false));
  };

  const exportcsv = async () => {
    const header = [
      { id: "first_name", title: "First Name" },
      { id: "last_name", title: "Last Name" },
      { id: "email", title: "Email" },
      { id: "role", title: "Role" },
      { id: "phone_number", title: "Mobile Number" },
      { id: "gender", title: "Gender" },
      { id: "age", title: "Age" },
    ];
    dispatch(userexport({ header, role: ["carer"] })).then(function (info) {
      window.location.href = info.payload;
    });
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      dispatch(
        careseekerlist({
          role: ["carer"],
          search: search,
          limit,
          page,
        })
      ).then(() => setLoading(false));
    }
  };
  return (
    <div>
      <Layout>
        <LoadingOverlay active={loading} text="Loading data...">
          <div class="dashboard_data_wrap">
            <div class="dashbord_cont_wrapper">
              <div class="search-container">
                <div class="search-box">
                  <span>
                    <i className="fas fa-search"></i>
                  </span>

                  <input
                    type="text"
                    placeholder="Search here..."
                    onChange={(e) => {
                      setSearch(e.target.value);
                      if (e.target.value == "") {
                        setLoading(true);
                        dispatch(
                          careseekerlist({
                            role: ["carer"],
                            limit,
                            page,
                          })
                        ).then(() => setLoading(false));
                      }
                    }}
                    onKeyDown={handleSearchKeyDown}
                  />
                  <button class="search-button" onClick={searchdata}>
                    Search
                  </button>
                </div>
                <div className="export-options">
                  <label htmlFor="export1">Export as :</label>
                  <select id="export1" name="export" onChange={exportcsv}>
                    <option value="csv">Please Select</option>

                    <option value="csv">Export .csv</option>
                  </select>
                </div>
              </div>
              <div class="user_list_wrap table-responsive">
                <table class="table table-stripd">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Date of Birth</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </thead>
                  <tbody>
                    {list?.data?.map(function (object, i) {
                      return (
                        <tr key={i}>
                          <td>
                            {" "}
                            <div className="d-flex align-items-center namebcx">
                              <img
                                src={object.profile_picture}
                                alt="profile"
                                width="31.33"
                                height="31.33"
                              />{" "}
                              <span>
                                {" "}
                                {UseFirstLetterCapital(
                                  object?.first_name + " " + object?.last_name
                                )}
                              </span>
                            </div>
                          </td>
                          <td>{object?.email}</td>
                          <td>
                            {object?.phone?.country_code !== undefined && (
                              <>
                                <Flag
                                  country={Contry(object?.phone?.country_code)}
                                  size={20}
                                />
                                {"  "}
                                {object?.phone?.country_code +
                                  " " +
                                  object?.phone?.number}
                              </>
                            )}
                          </td>
                          <td>{UseFirstLetterCapital(object?.gender)}</td>
                          <td>{calculateAge(object?.date_of_birth)}</td>
                          <td>
                            {moment(object?.date_of_birth).format("DD-MM-YYYY")}
                          </td>

                          <td>
                            {object?.is_active == true ? (
                              <div className="active_button">Active</div>
                            ) : (
                              <div className="active_button">In Active</div>
                            )}
                          </td>
                          <td>
                            <div className="iconsbcx">
                              <i
                                className="fas fa-eye "
                                onClick={() => {
                                  navigate(`/carer-details/${object?._id}`);
                                }}
                              ></i>
                              <i
                                className="fas fa-trash"
                                onClick={() => {
                                  setcareseekerobject(object);
                                  setShow(true);
                                }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  handlePageClick={handlePageClick}
                  pageCount={list?.totalItems}
                />
              </div>
            </div>
          </div>
          <DeleteModal
            show={show}
            handleClose={() => {
              setShow(false);
            }}
            handleDelete={handleDelete}
          />
        </LoadingOverlay>
      </Layout>
    </div>
  );
}
