import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "../Component/Pagination";
import {
  bookinlist,
  cancelbookingrequest,
  cancelreason,
  exportbooking,
  refund,
  updatebookingcarer,
} from "../Redux/Action/BookingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";
import { careseekerlistinfo } from "../Redux/Action/CareSeekerAction";
import { Button, Modal } from "react-bootstrap";
import Searchable from "react-searchable-dropdown";
import { useFormik } from "formik";
import checkCircle from "../assets/images/check-circle.png";
import cancleCircle from "../assets/images/cancel-circle.png";
import * as Yup from "yup";
import FirstLetterCapital from "../CustomHooks/FirstLetterCapital";
export default function BookingCancelRequest() {
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setshow] = useState(false);
  const [id, setid] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [canclerequestshow, setCancleRequestShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      bookinlist({
        search: "",
        status: "cancellation_pending",
        page: 1,
        limit: 15,
      })
    );
    dispatch(cancelreason());
  }, []);
  const list = useSelector((state) => state.Booking.booking);
  useEffect(() => {
    dispatch(
      careseekerlistinfo({
        role: ["carer"],
        limit: 10000,
        page: 1,
      })
    );
  }, []);

  const resasonlist = useSelector((state) => state.Booking.resasonlist);

  //resasonlist
  const handleClose = () => {
    setshow(false);
    setid("");
  };

  //updatebookingcarer

  const handelsearch = () => {
    if (search != "") {
      dispatch(
        bookinlist({
          search: search,
          status: "cancellation_pending",
          page: page,
          limit: 10,
        })
      );
    } else {
      dispatch(
        bookinlist({
          search: "",
          status: "cancellation_pending",
          page: page,
          limit: 10,
        })
      );
    }
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dispatch(
      bookinlist({
        search: "",
        status: "cancellation_pending",
        page: e.selected + 1,
        limit: 10,
      })
    );
  };

  const refundpayment = () => {
    dispatch(
      refund({
        booking_id: id,
      })
    ).then(function () {
      setshow(false);
      setid("");
      dispatch(
        bookinlist({
          search: "",
          status: "cancellation_pending",
          page: 1,
          limit: 15,
        })
      );
    });
  };

  //refund

  console.log(resasonlist, "resasonlistresasonlist");
  return (
    <Layout>
      <LoadingOverlay text="Loading data...">
        {" "}
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>

                <input
                  type="text"
                  placeholder="Search booking id..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button class="search-button" onClick={handelsearch}>
                  Search
                </button>
              </div>
              {/* <div className="export-options">
                <label htmlFor="export1">Export as :</label>
                <select id="export1" name="export" onChange={exportcsv}>
                  <option value="csv">Please Select</option>
                  <option value="csv">Export .csv</option>
                </select>
              </div> */}
            </div>
            <div class="user_list_wrap table-responsive">
              <table class="table table-stripd">
                <thead>
                  <th>Booking Number</th>

                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Cancellation reason</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {list?.data?.map(function (object, i) {
                    return (
                      <tr key={i}>
                        <td>{object?.booking_number}</td>

                        <td>
                          <div className=" align-items-center nameimgbcx">
                            <img
                              src={
                                object?.care_seeker?.profile_picture ==
                                undefined
                                  ? "https://i.imghippo.com/files/tE3170G.webp"
                                  : object?.care_seeker?.profile_picture
                              }
                              alt="profile"
                              width="31.33"
                              height="31.33"
                            />{" "}
                            <span className="namebcx">
                              {" "}
                              {FirstLetterCapital(
                                object?.care_seeker?.first_name +
                                  " " +
                                  object?.care_seeker?.last_name
                              )}
                            </span>
                          </div>
                        </td>
                        <td>{object?.care_seeker?.email}</td>
                        <td>
                          {object?.care_seeker?.phone?.country_code !=
                            undefined && (
                            <Flag
                              country={Contry(
                                object?.care_seeker?.phone?.country_code
                              )}
                              size={20}
                            />
                          )}{" "}
                          {object?.care_seeker?.phone?.country_code +
                            " " +
                            object?.care_seeker?.phone?.number}{" "}
                        </td>
                        <td>
                          {object?.payment_details?.total_amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                        </td>
                        <td>{object?.traillist?.cancelled_by?.reason}</td>

                        <td>
                          {" "}
                          <div className="iconsbcx">
                            <div className="checkbxt">
                              <i
                                class="fa fa-undo"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setshow(true);
                                  setid(object?._id);
                                }}
                              ></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={list?.totalItems}
              />
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Refund Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to proceed with the refund for this item?
            </p>
          </Modal.Body>
          <Modal.Footer>
            {/* Cancel Button */}
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            {/* Confirm Delete Button */}
            <Button
              variant="danger"
              onClick={() => {
                refundpayment();
              }}
            >
              Refund
            </Button>
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>
    </Layout>
  );
}
