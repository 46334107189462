import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export default function BlockModal({
  show,
  handleClose,
  handleBlockActive,
  title,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title} Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to {title} this item?</p>
      </Modal.Body>
      <Modal.Footer>
        {/* Cancel Button */}
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {/* Confirm Delete Button */}
        <Button variant="danger" onClick={handleBlockActive}>
          {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
