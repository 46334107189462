//promotion
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { toast } from "react-toastify";
export const Addpromotion = createAsyncThunk("promotion", async (value) => {
  try {
    let info = await Api.post(`promotion`, value);
    if (info.data.status_code == 200) {
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const GetPromotion = createAsyncThunk("promotion", async (value) => {
  try {
    let info = await Api.get(`promotion`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const deletePromotion = createAsyncThunk(
  "DeletePromotion",
  async (id) => {
    try {
      let info = await Api.delete(`promotion/${id}`);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
