import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Addpromotion,
  deletePromotion,
  GetPromotion,
} from "../Redux/Action/PromoCode";
import { adminupload } from "../Redux/Action/CareSeekerAction";
import LoadingOverlay from "react-loading-overlay";
import {
  createupdatecommission,
  getcommission,
} from "../Redux/Action/Commission";
import { toast } from "react-toastify";
export default function Commission() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true); // Loader state

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);

    formik.setFieldValue("admin_percentage", "");
    formik.setFieldValue("carer_percentage", "");
    formik.setFieldValue("document", "");
    formik.setFieldValue("type", "");

    formik.setFieldValue("percentage", "");
    setId("");
  };

  const formik = useFormik({
    initialValues: {
      type: "Basic",
      percentage: "",

      document: "",
    },
    validationSchema: Yup.object().shape({
      percentage: Yup.string().required("Fare percentage is required"),
      document: Yup.string(),
    }),
    onSubmit: (value) => {
      value.id = id;
      console.log(value.admin_percentage + value.carer_percentage);
      if (value.admin_percentage + value.carer_percentage > 100) {
        toast.error("Admin and Carer Percentage should be !00");
      } else {
        dispatch(createupdatecommission(value)).then(function () {
          dispatch(getcommission()).then(() => setLoading(false));
          handleClose();
          formik.resetForm();
        });
      }
    },
  });

  //Fetch the promotion

  useEffect(() => {
    dispatch(getcommission()).then(() => setLoading(false)); // Stop loader after data is fetched
  }, []);
  const commissionlist = useSelector((state) => state.Commission.commission);

  console.log(formik.errors);
  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        <div className="dashboard_data_wrap">
          <div className="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box"></div>
              <div class="export-options">
                <button className="common-btn" onClick={handleShow}>
                  +Add
                </button>
              </div>
            </div>

            <div className="user_list_wrap table-responsive">
              <table className="table table-stripd">
                <thead>
                  <th>Type</th>

                  <th>Percentage</th>

                  <th>Document</th>

                  <th>Action</th>
                </thead>
                <tbody>
                  {commissionlist?.map(function (object) {
                    return (
                      <tr>
                        <td>{object?.type}</td>

                        <td>{object?.percentage}</td>
                        <td>
                          <div style={{ textAlign: "center" }}>
                            <NavLink
                              to={object?.document}
                              target="_blank"
                              className="common-btn-assign"
                            >
                              View
                            </NavLink>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div style={{ textAlign: "center" }}>
                            <button
                              className="common-btn-assign"
                              onClick={() => {
                                setShow(true);
                                setId(object?._id);
                                formik.setFieldValue(
                                  "percentage",
                                  object?.percentage
                                );

                                formik.setFieldValue(
                                  "document",
                                  object?.document
                                );
                                formik.setFieldValue("type", object?.type);
                              }}
                            >
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{id == "" ? "Add" : "Update"} Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Banner Image Field */}

              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Plan type
                </label>

                <select
                  className="form-control"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  onBlur={formik.handleBlur}
                >
                  <option value="Basic">Basic</option>
                  <option value="Medium">Medium</option>
                  <option value="Premium">Premium</option>
                  <option value="Carer">Carer</option>
                  <option value="Admin">Admin</option>
                </select>
                {formik.touched.type &&
                  formik.submitCount > 0 &&
                  formik.errors.type && (
                    <div className=" text-danger">{formik.errors.type}</div>
                  )}
              </div>

              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Fare Percentage
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter  percentage"
                  name="percentage"
                  onChange={formik.handleChange}
                  value={formik.values.percentage}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.percentage &&
                  formik.submitCount > 0 &&
                  formik.errors.percentage && (
                    <div className=" text-danger">
                      {formik.errors.percentage}
                    </div>
                  )}
              </div>

              {/* <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Admin Percentage
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter admin percentage"
                  name="admin_percentage"
                  onChange={formik.handleChange}
                  value={formik.values.admin_percentage}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.admin_percentage &&
                  formik.submitCount > 0 &&
                  formik.errors.admin_percentage && (
                    <div className=" text-danger">
                      {formik.errors.admin_percentage}
                    </div>
                  )}
              </div>
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Carer Percentage
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter carer percentage"
                  name="carer_percentage"
                  onChange={formik.handleChange}
                  value={formik.values.carer_percentage}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.carer_percentage &&
                  formik.submitCount > 0 &&
                  formik.errors.carer_percentage && (
                    <div className=" text-danger">
                      {formik.errors.carer_percentage}
                    </div>
                  )}
              </div> */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Document
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="discountRate"
                  name="document"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    let fileinfo = new FormData();
                    fileinfo.append("file", file);
                    setLoading(true);
                    dispatch(adminupload(fileinfo)).then(function (info) {
                      formik.setFieldValue("document", info.payload.url);
                    });
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="modal-footer">
                {/* Submit Button */}
                <button type="submit" className="common-btn-assign">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Layout>
    </LoadingOverlay>
  );
}
