import { combineReducers, configureStore } from "@reduxjs/toolkit";
import Careseeker from "./Slices/Careseeker";
import SubAdmin from "./Slices/SubAdmin";
import PromotionSlice from "./Slices/PromotionSlice";
import BookingSlice from "./Slices/BookingSlice";
import WifilistSlice from "./Slices/WifilistSlice";
import CommissionSlice from "./Slices/CommissionSlice";
import TransactionSlice from "./Slices/TransactionSlice";
const rootReducer = combineReducers({
  careseeker: Careseeker,
  SubAdmin: SubAdmin,
  Promotion: PromotionSlice,
  Booking: BookingSlice,
  Wifi: WifilistSlice,
  Commission: CommissionSlice,
  Transaction: TransactionSlice,
});

export const Store = configureStore({
  reducer: rootReducer,
});
