import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import profileImage from "../../src/assets/images/Food_1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetails,
  updateAdminInfo,
  updatePassword,
} from "../Redux/Action/SubAdmin";
import { adminupload } from "../Redux/Action/CareSeekerAction";
import { useFormik } from "formik";
import * as Yup from "yup";
export default function Setting() {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");

  useEffect(() => {
    dispatch(getDetails());
  }, []);
  const admindetails = useSelector((state) => state.SubAdmin.admindetails);

  const hadeluploadfile = (event) => {
    const file = event.target.files[0];
    let fileinfo = new FormData();
    fileinfo.append("file", file);
    dispatch(adminupload(fileinfo)).then(function (info) {
      dispatch(
        updateAdminInfo({
          image: info.payload.url,
        })
      ).then(function () {
        dispatch(getDetails());
      });
      setImage(info.payload.url);
    });
  };
  //updatePassword

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      new_password: "",
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required("Old Password is required"),
      password: Yup.string().required("Password is required"),
      new_password: Yup.string()
        .required("New Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (value) => {
      dispatch(updatePassword(value));
    },
  });
  return (
    <Layout>
      <div className="dashboard_data_wrap">
        <div className="dashbord_cont_wrapper">
          <div className="profile-page-wrap">
            <div className="upload-preview">
              <div className="image-preview-wrap">
                <img
                  src={
                    admindetails?.image != undefined
                      ? admindetails?.image
                      : profileImage
                  }
                  alt="profile-image"
                />
                <label for="upload-file">{admindetails?.name}</label>
              </div>
              <div className="upload-input">
                <label for="image-upload">Change Profile Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    hadeluploadfile(e);
                  }}
                  id="image-upload"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="change-password-wrap">
                <label for="upload-file">Change Password:</label>
                <div className="change-pass-uploads">
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Current Password"
                      onChange={formik.handleChange}
                      value={formik.values.old_password}
                      onBlur={formik.handleBlur}
                      name="old_password"
                    />
                    {formik.touched.old_password &&
                      formik.submitCount > 0 &&
                      formik.errors.old_password && (
                        <div className="error">
                          {formik.errors.old_password}
                        </div>
                      )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      name="password"
                    />
                    {formik.touched.password &&
                      formik.submitCount > 0 &&
                      formik.errors.password && (
                        <div className="error">{formik.errors.password}</div>
                      )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      onChange={formik.handleChange}
                      value={formik.values.new_password}
                      onBlur={formik.handleBlur}
                      name="new_password"
                    />
                    {formik.touched.new_password &&
                      formik.submitCount > 0 &&
                      formik.errors.new_password && (
                        <div className="error">
                          {formik.errors.new_password}
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <button className="common-btn">Save</button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
