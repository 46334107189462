import React, { useEffect, useState, useRef } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Layout from "../layout/Layout";
import { useDispatch } from "react-redux";
import {
  CreateTermCondition,
  policieslist,
  updatepolicies,
} from "../Redux/Action/CareSeekerAction";
import { NavLink } from "react-router-dom";
import draftToHtml from "draftjs-to-html";

export default function About() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activeTab, setActiveTab] = useState("about"); // Initially set to 'terms'
  const [id, setId] = useState(""); // Initially set to 'terms'

  const [text, setText] = useState();
  const isMounted = useRef(false); // track whether the component is mounted
  const dispatch = useDispatch();
  useEffect(() => {
    isMounted.current = true; // component is mounted
    return () => {
      isMounted.current = false; // cleanup on unmount
    };
  }, []);

  const submit = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    if (id != "") {
      dispatch(
        updatepolicies({
          title: "about us",
          description: htmlContent,
          id: id,
        })
      );
    } else {
      dispatch(
        CreateTermCondition({
          title: "about us",
          description: htmlContent,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(policieslist()).then((response) => {
      const data = response?.payload?.find((item) => item.title === "about us");
      if (data && data.description) {
        setId(data._id);
        const blocksFromHTML = convertFromHTML(data.description);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        console.log(contentState, "dsdsds");
        setEditorState(EditorState.createWithContent(contentState));
      }
    });
  }, []);
  return (
    <Layout>
      <div>
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div className="tab-buttons">
              <NavLink
                to="/about"
                className={`ml-2 ${activeTab === "about" ? "termactive" : ""}`}
                onClick={() => setActiveTab("about")}
              >
                About Us
              </NavLink>
              <NavLink
                to="/policies"
                className={`tab1 ${
                  activeTab === "policies" ? "termactive" : ""
                }`}
                onClick={() => setActiveTab("policies")}
              >
                Privacy policies
              </NavLink>
              <NavLink
                to="/terms"
                className={`tab1 ${activeTab === "terms" ? "termactive" : ""}`}
                onClick={() => setActiveTab("terms")}
              >
                Terms and conditions
              </NavLink>
            </div>

            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "list",
                  "textAlign",
                  "history",
                ],
              }}
              editorStyle={{ height: "600px" }} // Added styles
            />
            <button type="button" className="term-policies" onClick={submit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
