import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Api from "../../Config/ApiConfig";

export const sendNotification = createAsyncThunk(
  "notification",
  async (value) => {
    try {
      let info = await Api.post("notification", value);
      if (info.data.status_code == 200) {
        console.log(info.data.status_code);
        toast.success(info.data.message);
      } else {
        toast.warning(info.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
