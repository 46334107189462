import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  wificreate,
  wifidelete,
  wifilist,
  wifiupdate,
} from "../Redux/Action/WifiAction";
import Pagination from "../Component/Pagination";
import LoadingOverlay from "react-loading-overlay";
import DeleteModal from "../Component/DeleteModal";
import {
  createLanguage,
  getLanguage,
  updateLanguage,
} from "../Redux/Action/Language";

export default function Language() {
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [id, setid] = useState("");

  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true); // Loader state
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    setid("");
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("title is required"),
    }),
    onSubmit: (value) => {
      setLoading(true);
      if (id != "") {
        value.id = id;
        dispatch(updateLanguage(value)).then(function () {
          dispatch(getLanguage()).then(() => setLoading(false));
          setid("");
        });
      } else {
        dispatch(createLanguage(value)).then(function () {
          dispatch(getLanguage()).then(() => setLoading(false));
        });
      }

      formik.resetForm();
      setShow(false);
    },
  });

  useEffect(() => {
    dispatch(getLanguage()).then(() => setLoading(false));
  }, []);
  const list = useSelector((state) => state.Wifi.wifiArr);

  const handleDelete = () => {
    dispatch(wifidelete(id)).then(function () {
      setDeleteShow(false);
      dispatch(getLanguage({})).then(() => setLoading(false));
    });
    setid("");
  };
  return (
    <Layout>
      <LoadingOverlay active={loading} text="Loading data...">
        {" "}
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box"></div>
              <div class="export-options">
                <button
                  className="common-btn"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  +Add
                </button>
                <button className="ml-2 common-btn">Save</button>
              </div>
            </div>
            <div class="user_list_wrap table-responsive">
              <table class="table table-stripd">
                <thead>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {list?.wifi?.map(function (object, i) {
                    return (
                      <tr key={i}>
                        <td>
                          <div>
                            {" "}
                            <i className="fas fa-wifi mr-2"></i>
                            {object.title}
                            <span>
                              {object.isActive == false ? "Enable" : "Disable"}
                            </span>
                          </div>
                        </td>
                        <td>{object?.locationname}</td>
                        <td>{object.location?.coordinates[0]}</td>
                        <td>{object.location?.coordinates[1]}</td>
                        <td>
                          <div className="action-btn">
                            <i
                              className="fas fa-pencil"
                              onClick={() => {
                                formik.setFieldValue("title", object.title);

                                setShow(true);
                                setid(object?._id);
                              }}
                            ></i>
                            <i className="fas fa-eye"></i>
                            <i
                              className="fas fa-trash"
                              onClick={() => {
                                setid(object?._id);
                                setDeleteShow(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <DeleteModal
                show={deleteShow}
                handleClose={() => {
                  setDeleteShow(false);
                }}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{id != "" ? "Update" : "Add"} Language</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Banner Image Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter title"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title &&
                  formik.submitCount > 0 &&
                  formik.errors.title && (
                    <div className="error">{formik.errors.title}</div>
                  )}
              </div>

              <div className="modal-footer">
                {/* Close Button */}

                {/* Submit Button */}
                <button type="submit" className="common-btn">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingOverlay>
    </Layout>
  );
}
