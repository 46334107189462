import React from "react";
import ReactPaginate from "react-paginate";
export default function Pagination({ handlePageClick, pageCount }) {
  return (
    <div className="pagination">
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        pageCount={Math.ceil(pageCount / 10)}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
