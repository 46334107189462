import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../Redux/Action/AuthAction";

export default function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboard());
  }, []);
  //dashboard
  const list = useSelector((state) => state.SubAdmin.dashboard);
  console.log(list);

  return (
    <Layout>
      <div className="dashboard_data_wrap">
        <div className="dashboard-card">
          <div className="dashboard-header-card">
            <div className="dashboard-content">
              {/* Adding overview title */}
              <h2>Overview</h2>

              <div className="dashboard-metrics">
                <div className="metric">
                  <div className="bxcontent">
                    <span>{list?.totalbookingnmber}</span>
                    <p>Total number of Bookings</p>
                  </div>
                </div>

                <div className="metric">
                  <div className="bxcontent">
                    <span>
                      {list?.totalRevenue?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>
                    <p>Total Revenue(Last 7 days)</p>
                  </div>
                </div>

                <div className="metric">
                  <div className="bxcontent">
                    <span>{list?.bookingRequest}</span>
                    <p>Total Booking Requests</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
