import { useState, useEffect } from "react";
import usericon from "../assets/images/admin_user_pic.jpg";

export default function FirstLetterCapital(value) {
  return String(value).charAt(0).toUpperCase() + String(value).slice(1);
  // Always return the state value
}

export const dummyimage = () => {
  return usericon;
};
