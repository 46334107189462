import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
export const careseekerlist = createAsyncThunk(
  "careseekerlist",
  async (value) => {
    try {
      let info = await Api.post(`user`, value);
      if (info.data.status_code == 200) {
        return info.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const careseekerlistinfo = createAsyncThunk(
  "careseekerlistinfo",
  async (value) => {
    try {
      let info = await Api.post(`user`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const carerAction = createAsyncThunk("carer", async (value) => {
  try {
    let info = await Api.post(`carer`, value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});
//latest

export const CreateTermCondition = createAsyncThunk(
  "termcondition",
  async (value) => {
    try {
      let info = await Api.post("policy", value);
      if (info.data.status_code == 200) {
        toast.success(info.data.data.message);
      } else {
        toast.warning(info.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const policieslist = createAsyncThunk("termcondition", async (value) => {
  try {
    let info = await Api.get("policy");
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const updatepolicies = createAsyncThunk(
  "updatepolicies",
  async (value) => {
    try {
      let info = await Api.put(`policy/${value.id}`, {
        title: value.title,
        description: value.description,
      });
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.warning(info.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updatestaus = createAsyncThunk("updatestaus", async (value) => {
  try {
    let info = await Api.put(`user`, value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});
export const getprofiledetail = createAsyncThunk("user", async (value) => {
  try {
    let info = await Api.get(`user/${value.id}`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const getDocument = createAsyncThunk("document", async (value) => {
  try {
    let info = await Api.get(`document`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const adminupload = createAsyncThunk("admin-upload", async (value) => {
  try {
    let info = await Api.post("admin-upload", value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.warning(info.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
});
