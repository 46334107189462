import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { toast } from "react-toastify";

export const createupdatecommission = createAsyncThunk(
  "/create-update-commission",
  async (value) => {
    try {
      let info = await Api.post(`/create-update-commission`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const getcommission = createAsyncThunk(
  "/get-commission",
  async (value) => {
    try {
      let info = await Api.get(`/get-commission`);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);
