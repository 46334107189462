import { createSlice } from "@reduxjs/toolkit";
import { wifilist } from "../Action/WifiAction";

const WifilistSlice = createSlice({
  name: "WifilistSlice",
  initialState: {
    wifiArr: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(wifilist.fulfilled, (state, action) => {
      state.wifiArr = action.payload;
    });
  },
});

export default WifilistSlice.reducer;
