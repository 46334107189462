import { createSlice } from "@reduxjs/toolkit";
import { GetPromotion } from "../Action/PromoCode";
import {
  bookinlist,
  cancelreason,
  getbookingdetail,
} from "../Action/BookingAction";

const BookingSlice = createSlice({
  name: "Booking",
  initialState: {
    booking: [],
    bookingdetails: {},
    resasonlist: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookinlist.fulfilled, (state, action) => {
        state.booking = action.payload;
      })
      .addCase(getbookingdetail.fulfilled, (state, action) => {
        state.bookingdetails = action.payload;
      })
      .addCase(cancelreason.fulfilled, (state, action) => {
        state.resasonlist = action.payload;
      });
  },
});

export default BookingSlice.reducer;
