import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createAdmin } from "../Redux/Action/AuthAction";

export default function AddSubAdmin() {
  //createAdmin
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      permission: [],
      role: "Sub-Admin",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
      role: Yup.mixed()
        .oneOf(["Admin", "Sub-Admin"], "Invalid role selection")
        .required("Role is required"),
      name: Yup.string().required("User Name is required"),
      permission: Yup.array().required("Permission is required"),
    }),
    onSubmit: (value) => {
      console.log(value);
      dispatch(createAdmin(value)).then(function () {
        navigate("/sub-admin-management");
      });
    },
  });
  console.log(formik.errors);
  return (
    <Layout>
      <div className="dashboard_data_wrap sub-admin-management">
        <div className="dashbord_cont_wrapper">
          <form onSubmit={formik.handleSubmit}>
            <div class="search-container">
              <div class="search-box">
                <input type="text" readOnly />

                <button class="search-button">Search</button>
              </div>
              <div class="export-options">
                <button type="submit" className="common-btn">
                  Save
                </button>
              </div>
            </div>

            <div className="user_list_wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="formwrap-bcx">
                    <div className="form-group">
                      <label>User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="User Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        name="name"
                      />
                      {formik.touched.name &&
                        formik.submitCount > 0 &&
                        formik.errors.name && (
                          <div className="error">{formik.errors.name}</div>
                        )}
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="type"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        name="email"
                      />
                      {formik.touched.email &&
                        formik.submitCount > 0 &&
                        formik.errors.email && (
                          <div className="error">{formik.errors.email}</div>
                        )}
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        name="password"
                      />
                      {formik.touched.password &&
                        formik.submitCount > 0 &&
                        formik.errors.password && (
                          <div className="error">{formik.errors.password}</div>
                        )}
                    </div>
                    <div className="form-group">
                      <label>Roll Name</label>

                      <select
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.role}
                        name="role"
                      >
                        <option value="Sub-Admin">Sub-Admin</option>

                        <option value="Admin">Admin</option>
                      </select>
                      {formik.touched.role &&
                        formik.submitCount > 0 &&
                        formik.errors.role && (
                          <span className="error">{formik.errors.role}</span>
                        )}
                    </div>
                    <div className="form-group">
                      <label>Permission</label>
                      <div className="admin-permission">
                        {formik.touched.permission &&
                          formik.submitCount > 0 &&
                          formik.errors.permission && (
                            <span className="error">
                              {formik.errors.permission}
                            </span>
                          )}
                        <ul>
                          {[
                            {
                              id: "dashboard",
                              title: "Dashboard",
                              url: "/dashboard",
                              iconClass: "fas fa-home", // Store class as a string
                            },
                            {
                              id: "careseeker",
                              title: "Care Seeker",
                              url: "/care-seeker",
                              iconClass: "fas fa-user",
                            },
                            {
                              id: "carer",
                              title: "Carer",
                              url: "/carer",
                              iconClass: "fas fa-user",
                            },
                            {
                              id: "booking-request",
                              title: "Booking Request",
                              url: "/booking-request-from-care-seeker",
                              iconClass: "fas fa-shopping-bag",
                            },
                            {
                              id: "booking-cancel-request",
                              title: "Booking Cancel Request",
                              url: "/booking-cancel-request",
                              iconClass: "fas fa-shopping-bag",
                            },
                            {
                              id: "end-trip",
                              title: "Pay to Carer",
                              url: "/end-trip",
                              iconClass: "fas fa-shopping-bag",
                            },

                            {
                              id: "booking",
                              title: "Booking",
                              url: "/booking",
                              iconClass: "fas fa-shopping-bag",
                            },
                            {
                              id: "transaction",
                              title: "Transactions",
                              url: "/transaction",
                              iconClass: "fas fa-user",
                            },
                            {
                              id: "offer",
                              title: "Offers",
                              url: "/offer",
                              iconClass: "fas fa-gift",
                            },
                            {
                              id: "report",
                              title: "Reports",
                              url: "/report",
                              iconClass: "fas fa-user",
                            },
                            {
                              id: "chat",
                              title: "Chats",
                              url: "/chat",
                              iconClass: "fas fa-user",
                            },
                            {
                              id: "content-management",
                              title: "Content Management",
                              url: "/content-management",
                              iconClass: "fas fa-files-o",
                            },
                            {
                              id: "wifi",
                              title: "Wifi Controls",
                              url: "/wifi",
                              iconClass: "fas fa-wifi",
                            },
                            {
                              id: "notifications",
                              title: "Notifications",
                              url: "/notifications",
                              iconClass: "fas fa-bell-o",
                            },
                            {
                              id: "setting",
                              title: "Settings",
                              url: "/settings",
                              iconClass: "fas fa-cog",
                            },
                            {
                              id: "sub-admin-management",
                              title: "Sub Admin Management",
                              url: "/sub-admin-management",
                              iconClass: "fas fa-user-cog",
                            },
                            {
                              id: "sub-admin-management",
                              title: "Service Charge",
                              url: "/charge",
                              iconClass: "fas fa-user-cog",
                            },
                          ].map((permission) => (
                            <li key={permission.id}>
                              <div className="permission-checkbx">
                                <input
                                  type="checkbox"
                                  id={permission.id}
                                  name="permission"
                                  value={permission.id}
                                  onChange={(e) => {
                                    const { checked } = e.target;
                                    if (checked) {
                                      // Add the permission object in { title, url } format
                                      formik.setFieldValue("permission", [
                                        ...formik.values.permission,
                                        {
                                          title: permission.title,
                                          url: permission.url,
                                          icon: permission.iconClass,
                                        },
                                      ]);
                                    } else {
                                      // Remove the permission object by filtering based on the title
                                      formik.setFieldValue(
                                        "permission",
                                        formik.values.permission.filter(
                                          (item) =>
                                            item.title !== permission.title
                                        )
                                      );
                                    }
                                  }}
                                  checked={formik.values.permission.some(
                                    (item) => item.title === permission.title
                                  )}
                                />
                                <label htmlFor={permission.id}>
                                  {permission.title}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
