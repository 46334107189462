import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  bookinlist,
  cancelreason,
  exportbooking,
} from "../Redux/Action/BookingAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Pagination from "../Component/Pagination";
import FirstLetterCapital from "../CustomHooks/FirstLetterCapital";

export default function Booking() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      bookinlist({
        search: "",
        status: "accepted_by_carer",
        page: 1,
        limit: 15,
      })
    );
  }, []);
  const list = useSelector((state) => state.Booking.booking);
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dispatch(
      bookinlist({
        search: "",
        status: "accepted_by_carer",
        page: e.selected + 1,
        limit: 10,
      })
    );
  };

  const handelsearch = () => {
    if (search != "") {
      dispatch(
        bookinlist({
          search: search,
          status: "accepted_by_carer",
          page: page,
          limit: 10,
        })
      );
    } else {
      dispatch(
        bookinlist({
          search: "",
          status: "accepted_by_carer",
          page: page,
          limit: 10,
        })
      );
    }
  };

  const exportcsv = async () => {
    const header = [
      {
        id: "booking_number",
        title: "Booking Number",
      },
      {
        id: "care_seeker",
        title: "Care Seeker",
      },
      {
        id: "booking_status",
        title: "Status",
      },
      {
        id: "createdAt",
        title: "Created At",
      },
    ];
    dispatch(exportbooking({ header, status: "accepted_by_carer" })).then(
      function (info) {
        window.location.href = info.payload;
      }
    );
  };

  function convertToCapitalized(str) {
    return str
      .split("_") // Split by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join the words with a space
  }

  return (
    <Layout>
      <div class="dashboard_data_wrap">
        <div class="dashbord_cont_wrapper">
          <div class="search-container">
            <div class="search-box">
              <span>
                <i className="fas fa-search"></i>
              </span>

              <input
                type="text"
                placeholder="Search booking id..."
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value == "") {
                    dispatch(
                      bookinlist({
                        search: "",
                        status: "accepted_by_carer",
                        page: 1,
                        limit: 15,
                      })
                    );
                  }
                }}
              />
              <button class="search-button" onClick={handelsearch}>
                Search
              </button>
            </div>
            <div className="export-options">
              <label htmlFor="export1">Export as :</label>
              <select id="export1" name="export" onChange={exportcsv}>
                <option value="csv">Please Select</option>
                <option value="csv">Export .csv</option>
              </select>
            </div>
          </div>
          <div class="user_list_wrap table-responsive">
            <table class="table table-stripd">
              <thead>
                <th>Booking ID</th>
                <th>Booked By</th>
                <th>Booking Time</th>
                <th>Booking Date</th>

                <th>Departure Date</th>

                <th style={{ alignContent: "center", paddingLeft: "100px" }}>
                  {" "}
                  Booking Status
                </th>
              </thead>
              <tbody>
                {list?.data?.map(function (object, i) {
                  return (
                    <tr>
                      <td>{object?.booking_number}</td>
                      <td>
                        <div className=" align-items-center nameimgbcx">
                          <img
                            src={object?.care_seeker?.profile_picture}
                            alt="profile"
                            width="31.33"
                            height="31.33"
                          />{" "}
                          <span className="namebcx">
                            {" "}
                            {FirstLetterCapital(
                              object?.care_seeker?.first_name +
                                " " +
                                object?.care_seeker?.last_name
                            )}
                          </span>
                        </div>
                      </td>
                      <td>{moment(object?.createdAt).format("hh:mm A")}</td>
                      <td>{moment(object?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        {moment(object?.flight_details?.departure_date).format(
                          "DD-MM-YYYY"
                        )}
                      </td>

                      <td>
                        {" "}
                        <div style={{ textAlign: "end" }}>
                          {convertToCapitalized(object?.booking_status)}
                          <span style={{ marginLeft: "10px" }}>
                            <i
                              className="fas fa-eye "
                              onClick={() => {
                                navigate(`/booking-details/${object?._id}`);
                              }}
                            ></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={list?.totalItems}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
