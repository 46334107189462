import { createSlice } from "@reduxjs/toolkit";
import { getDetails, getSubadminlist } from "../Action/SubAdmin";
import { dashboard } from "../Action/AuthAction";

const Subadmin = createSlice({
  name: "Subadmin",
  initialState: {
    subadminlist: [],
    admindetails: "",
    dashboard: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubadminlist.fulfilled, (state, action) => {
        state.subadminlist = action.payload;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.admindetails = action.payload;
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        state.dashboard = action.payload;
      });
  },
});

export default Subadmin.reducer;
