import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getSubadminlist, userexport } from "../Redux/Action/SubAdmin";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { NavLink, useNavigate } from "react-router-dom";
import DeleteModal from "../Component/DeleteModal";
import { deleteAdmin } from "../Redux/Action/AuthAction";
import Pagination from "../Component/Pagination";

export default function SubAdminManagement() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSubadminlist({ limit, page })).then(() => setLoading(false));
  }, []);

  const list = useSelector((state) => state.SubAdmin.subadminlist);
  const searchdata = () => {
    setLoading(true); // Start loader when searching
    if (search === "") {
      dispatch(
        getSubadminlist({
          limit,
          page,
        })
      ).then(() => setLoading(false)); // Stop loader after data is fetched
    } else {
      dispatch(
        getSubadminlist({
          search: search,
          limit,
          page,
        })
      ).then(() => setLoading(false)); // Stop loader after search is done
    }
  };
  //deleteAdmin
  const handleDelete = () => {
    dispatch(deleteAdmin(id)).then(function () {
      setShow(false);
      dispatch(
        getSubadminlist({
          search: search,
          limit,
          page,
        })
      ).then(() => setLoading(false));
    });
  };
  const handlePageClick = (e) => {
    setPage(e.selected);
    setLoading(true);
    dispatch(getSubadminlist({ limit, page: e.selected + 1 })).then(() =>
      setLoading(false)
    );
  };

  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        <div className="dashboard_data_wrap sub-admin-management">
          <div className="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>

                <input
                  type="text"
                  placeholder="Search user id..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button class="search-button" onClick={searchdata}>
                  Search
                </button>
              </div>
              <div class="export-options">
                <button className="common-btn">
                  <NavLink to="/add-sub-admin">+Add</NavLink>
                </button>
                <button className="common-btn">Save</button>
              </div>
            </div>

            <div className="user_list_wrap table-responsive">
              <table className="table table-stripd">
                <thead>
                  <th>User Name</th>
                  <th>Role Name</th>
                  <th>Created On</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {list?.users?.map(function (object, i) {
                    return (
                      <tr>
                        <td>{object?.name}</td>
                        <td>{object?.role}</td>
                        <td>
                          {moment(object?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}>
                            <i
                              className="fas fa-eye"
                              onClick={() => {
                                navigate(`/update-subadmin/${object?._id}`);
                              }}
                            ></i>
                            <i
                              className="fas fa-trash"
                              onClick={() => {
                                setShow(true);
                                setId(object?._id);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={list?.count}
            />
          </div>
        </div>
      </Layout>
      <DeleteModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        handleDelete={handleDelete}
      />
    </LoadingOverlay>
  );
}
