import React from "react";

export default function Contry(country_code) {
  const countriesData = [
    { name: "Afghanistan", code: "+93", iso: "AF", flag: "af.svg" },
    { name: "Albania", code: "+355", iso: "AL", flag: "al.svg" },
    { name: "Algeria", code: "+213", iso: "DZ", flag: "dz.svg" },
    { name: "American Samoa", code: "+1 684", iso: "AS", flag: "as.svg" },
    { name: "Andorra", code: "+376", iso: "AD", flag: "ad.svg" },
    { name: "Angola", code: "+244", iso: "AO", flag: "ao.svg" },
    { name: "Anguilla", code: "+1 264", iso: "AI", flag: "ai.svg" },
    { name: "Antigua and Barbuda", code: "+1 268", iso: "AG", flag: "ag.svg" },
    { name: "Argentina", code: "+54", iso: "AR", flag: "ar.svg" },
    { name: "Armenia", code: "+374", iso: "AM", flag: "am.svg" },
    { name: "Aruba", code: "+297", iso: "AW", flag: "aw.svg" },
    { name: "Australia", code: "+61", iso: "AU", flag: "au.svg" },
    { name: "Austria", code: "+43", iso: "AT", flag: "at.svg" },
    { name: "Azerbaijan", code: "+994", iso: "AZ", flag: "az.svg" },
    { name: "Bahamas", code: "+1 242", iso: "BS", flag: "bs.svg" },
    { name: "Bahrain", code: "+973", iso: "BH", flag: "bh.svg" },
    { name: "Bangladesh", code: "+880", iso: "BD", flag: "bd.svg" },
    { name: "Barbados", code: "+1 246", iso: "BB", flag: "bb.svg" },
    { name: "Belarus", code: "+375", iso: "BY", flag: "by.svg" },
    { name: "Belgium", code: "+32", iso: "BE", flag: "be.svg" },
    { name: "Belize", code: "+501", iso: "BZ", flag: "bz.svg" },
    { name: "Benin", code: "+229", iso: "BJ", flag: "bj.svg" },
    { name: "Bermuda", code: "+1 441", iso: "BM", flag: "bm.svg" },
    { name: "Bhutan", code: "+975", iso: "BT", flag: "bt.svg" },
    { name: "Bolivia", code: "+591", iso: "BO", flag: "bo.svg" },
    { name: "Bosnia and Herzegovina", code: "+387", iso: "BA", flag: "ba.svg" },
    { name: "Botswana", code: "+267", iso: "BW", flag: "bw.svg" },
    { name: "Brazil", code: "+55", iso: "BR", flag: "br.svg" },
    {
      name: "British Indian Ocean Territory",
      code: "+246",
      iso: "IO",
      flag: "io.svg",
    },
    {
      name: "British Virgin Islands",
      code: "+1 284",
      iso: "VG",
      flag: "vg.svg",
    },
    { name: "Brunei", code: "+673", iso: "BN", flag: "bn.svg" },
    { name: "Bulgaria", code: "+359", iso: "BG", flag: "bg.svg" },
    { name: "Burkina Faso", code: "+226", iso: "BF", flag: "bf.svg" },
    { name: "Burundi", code: "+257", iso: "BI", flag: "bi.svg" },
    { name: "Cambodia", code: "+855", iso: "KH", flag: "kh.svg" },
    { name: "Cameroon", code: "+237", iso: "CM", flag: "cm.svg" },
    { name: "Canada", code: "+1", iso: "CA", flag: "ca.svg" },
    { name: "Cape Verde", code: "+238", iso: "CV", flag: "cv.svg" },
    { name: "Cayman Islands", code: "+ 345", iso: "KY", flag: "ky.svg" },
    {
      name: "Central African Republic",
      code: "+236",
      iso: "CF",
      flag: "cf.svg",
    },
    { name: "Chad", code: "+235", iso: "TD", flag: "td.svg" },
    { name: "Chile", code: "+56", iso: "CL", flag: "cl.svg" },
    { name: "China", code: "+86", iso: "CN", flag: "cn.svg" },
    { name: "Christmas Island", code: "+61", iso: "CX", flag: "cx.svg" },
    { name: "Colombia", code: "+57", iso: "CO", flag: "co.svg" },
    { name: "Comoros", code: "+269", iso: "KM", flag: "km.svg" },
    { name: "Congo", code: "+242", iso: "CG", flag: "cg.svg" },
    {
      name: "Congo, Dem. Rep. of (Zaire)",
      code: "+243",
      iso: "CD",
      flag: "cd.svg",
    },
    { name: "Cook Islands", code: "+682", iso: "CK", flag: "ck.svg" },
    { name: "Costa Rica", code: "+506", iso: "CR", flag: "cr.svg" },
    { name: "Croatia", code: "+385", iso: "HR", flag: "hr.svg" },
    { name: "Cuba", code: "+53", iso: "CU", flag: "cu.svg" },
    { name: "Curacao", code: "+599", iso: "CW", flag: "cw.svg" },
    { name: "Cyprus", code: "+537", iso: "CY", flag: "cy.svg" },
    { name: "Czech Republic", code: "+420", iso: "CZ", flag: "cz.svg" },
    { name: "Denmark", code: "+45", iso: "DK", flag: "dk.svg" },
    { name: "Djibouti", code: "+253", iso: "DJ", flag: "dj.svg" },
    { name: "Dominica", code: "+1 767", iso: "DM", flag: "dm.svg" },
    { name: "Dominican Republic", code: "+1 809", iso: "DO", flag: "do.svg" },
    { name: "East Timor", code: "+670", iso: "TR", flag: "tr.svg" },
    { name: "Ecuador", code: "+593", iso: "EC", flag: "ec.svg" },
    { name: "Egypt", code: "+20", iso: "EG", flag: "eg.svg" },
    { name: "El Salvador", code: "+503", iso: "SV", flag: "sv.svg" },
    { name: "Equatorial Guinea", code: "+240", iso: "GQ", flag: "gq.svg" },
    { name: "Eritrea", code: "+291", iso: "ER", flag: "er.svg" },
    { name: "Estonia", code: "+372", iso: "EE", flag: "ee.svg" },
    { name: "Ethiopia", code: "+251", iso: "ET", flag: "et.svg" },
    { name: "Falkland Islands", code: "+500", iso: "FK", flag: "fk.svg" },
    { name: "Faroe Islands", code: "+298", iso: "FO", flag: "fo.svg" },
    { name: "Fiji", code: "+679", iso: "FJ", flag: "fj.svg" },
    { name: "Finland", code: "+358", iso: "FI", flag: "fi.svg" },
    { name: "France", code: "+33", iso: "FR", flag: "fr.svg" },
    { name: "French Guiana", code: "+594", iso: "GF", flag: "gf.svg" },
    { name: "French Polynesia", code: "+689", iso: "PF", flag: "pf.svg" },
    { name: "Gabon", code: "+241", iso: "GA", flag: "ga.svg" },
    { name: "Gambia", code: "+220", iso: "GM", flag: "gm.svg" },
    { name: "Georgia", code: "+995", iso: "GE", flag: "ge.svg" },
    { name: "Germany", code: "+49", iso: "DE", flag: "de.svg" },
    { name: "Ghana", code: "+233", iso: "GH", flag: "gh.svg" },
    { name: "Greece", code: "+30", iso: "GR", flag: "gr.svg" },
    { name: "Greenland", code: "+299", iso: "GL", flag: "gl.svg" },
    { name: "Grenada", code: "+1 473", iso: "GD", flag: "gd.svg" },
    { name: "Guadeloupe", code: "+590", iso: "GP", flag: "gp.svg" },
    { name: "Guam", code: "+1 671", iso: "GU", flag: "gu.svg" },
    { name: "Guatemala", code: "+502", iso: "GT", flag: "gt.svg" },
    { name: "Guinea", code: "+224", iso: "GN", flag: "gn.svg" },
    { name: "Guinea-Bissau", code: "+245", iso: "GW", flag: "gw.svg" },
    { name: "Guyana", code: "+592", iso: "GY", flag: "gy.svg" },
    { name: "Haiti", code: "+509", iso: "HT", flag: "ht.svg" },
    { name: "Honduras", code: "+504", iso: "HN", flag: "hn.svg" },
    { name: "Hungary", code: "+36", iso: "HU", flag: "hu.svg" },
    { name: "Iceland", code: "+354", iso: "IS", flag: "is.svg" },
    { name: "India", code: "+91", iso: "IN", flag: "in.svg" },
    { name: "Indonesia", code: "+62", iso: "ID", flag: "id.svg" },
    { name: "Iran", code: "+98", iso: "IR", flag: "ir.svg" },
    { name: "Iraq", code: "+964", iso: "IQ", flag: "iq.svg" },
    { name: "Ireland", code: "+353", iso: "IE", flag: "ie.svg" },
    { name: "Israel", code: "+972", iso: "IL", flag: "il.svg" },
    { name: "Italy", code: "+39", iso: "IT", flag: "it.svg" },
    { name: "Jamaica", code: "+1 876", iso: "JM", flag: "jm.svg" },
    { name: "Japan", code: "+81", iso: "JP", flag: "jp.svg" },
    { name: "Jordan", code: "+962", iso: "JO", flag: "jo.svg" },
    { name: "Kazakhstan", code: "+7", iso: "KZ", flag: "kz.svg" },
    { name: "Kenya", code: "+254", iso: "KE", flag: "ke.svg" },
    { name: "Kiribati", code: "+686", iso: "KI", flag: "ki.svg" },
    { name: "Korea, North", code: "+850", iso: "KP", flag: "kp.svg" },
    { name: "Korea, South", code: "+82", iso: "KR", flag: "kr.svg" },
    { name: "Kuwait", code: "+965", iso: "KW", flag: "kw.svg" },
    { name: "Kyrgyzstan", code: "+996", iso: "KG", flag: "kg.svg" },
    { name: "Laos", code: "+856", iso: "LA", flag: "la.svg" },
    { name: "Latvia", code: "+371", iso: "LV", flag: "lv.svg" },
    { name: "Lebanon", code: "+961", iso: "LB", flag: "lb.svg" },
    { name: "Lesotho", code: "+266", iso: "LS", flag: "ls.svg" },
    { name: "Liberia", code: "+231", iso: "LR", flag: "lr.svg" },
    { name: "Libya", code: "+218", iso: "LY", flag: "ly.svg" },
    { name: "Liechtenstein", code: "+423", iso: "LI", flag: "li.svg" },
    { name: "Lithuania", code: "+370", iso: "LT", flag: "lt.svg" },
    { name: "Luxembourg", code: "+352", iso: "LU", flag: "lu.svg" },
    { name: "Macau", code: "+853", iso: "MO", flag: "mo.svg" },
    { name: "Madagascar", code: "+261", iso: "MG", flag: "mg.svg" },
    { name: "Malawi", code: "+265", iso: "MW", flag: "mw.svg" },
    { name: "Malaysia", code: "+60", iso: "MY", flag: "my.svg" },
    { name: "Maldives", code: "+960", iso: "MV", flag: "mv.svg" },
    { name: "Mali", code: "+223", iso: "ML", flag: "ml.svg" },
    { name: "Malta", code: "+356", iso: "MT", flag: "mt.svg" },
    { name: "Marshall Islands", code: "+692", iso: "MH", flag: "mh.svg" },
    { name: "Martinique", code: "+596", iso: "MQ", flag: "mq.svg" },
    { name: "Mauritania", code: "+222", iso: "MR", flag: "mr.svg" },
    { name: "Mauritius", code: "+230", iso: "MU", flag: "mu.svg" },
    { name: "Mayotte", code: "+262", iso: "YT", flag: "yt.svg" },
    { name: "Mexico", code: "+52", iso: "MX", flag: "mx.svg" },
    { name: "Micronesia", code: "+691", iso: "FM", flag: "fm.svg" },
    { name: "Moldova", code: "+373", iso: "MD", flag: "md.svg" },
    { name: "Monaco", code: "+377", iso: "MC", flag: "mc.svg" },
    { name: "Mongolia", code: "+976", iso: "MN", flag: "mn.svg" },
    { name: "Montenegro", code: "+382", iso: "ME", flag: "me.svg" },
    { name: "Montserrat", code: "+1 664", iso: "MS", flag: "ms.svg" },
    { name: "Morocco", code: "+212", iso: "MA", flag: "ma.svg" },
    { name: "Mozambique", code: "+258", iso: "MZ", flag: "mz.svg" },
    { name: "Myanmar", code: "+95", iso: "MM", flag: "mm.svg" },
    { name: "Namibia", code: "+264", iso: "NA", flag: "na.svg" },
    { name: "Nauru", code: "+674", iso: "NR", flag: "nr.svg" },
    { name: "Nepal", code: "+977", iso: "NP", flag: "np.svg" },
    { name: "Netherlands", code: "+31", iso: "NL", flag: "nl.svg" },
    { name: "New Caledonia", code: "+687", iso: "NC", flag: "nc.svg" },
    { name: "New Zealand", code: "+64", iso: "NZ", flag: "nz.svg" },
    { name: "Nicaragua", code: "+505", iso: "NI", flag: "ni.svg" },
    { name: "Niger", code: "+227", iso: "NE", flag: "ne.svg" },
    { name: "Nigeria", code: "+234", iso: "NG", flag: "ng.svg" },
    { name: "Niue", code: "+683", iso: "NU", flag: "nu.svg" },
    { name: "Norfolk Island", code: "+672", iso: "NF", flag: "nf.svg" },
    {
      name: "Northern Mariana Islands",
      code: "+1 670",
      iso: "MP",
      flag: "mp.svg",
    },
    { name: "Norway", code: "+47", iso: "NO", flag: "no.svg" },
    { name: "Oman", code: "+968", iso: "OM", flag: "om.svg" },
    { name: "Pakistan", code: "+92", iso: "PK", flag: "pk.svg" },
    { name: "Palau", code: "+680", iso: "PW", flag: "pw.svg" },
    { name: "Palestine", code: "+970", iso: "PS", flag: "ps.svg" },
    { name: "Panama", code: "+507", iso: "PA", flag: "pa.svg" },
    { name: "Papua New Guinea", code: "+675", iso: "PG", flag: "pg.svg" },
    { name: "Paraguay", code: "+595", iso: "PY", flag: "PY", flag: "py.svg" },
    { name: "Peru", code: "+51", iso: "PE", flag: "pe.svg" },
    { name: "Philippines", code: "+63", iso: "PH", flag: "ph.svg" },
    { name: "Poland", code: "+48", iso: "PL", flag: "pl.svg" },
    { name: "Portugal", code: "+351", iso: "PT", flag: "pt.svg" },
    { name: "Puerto Rico", code: "+1 787", iso: "PR", flag: "pr.svg" },
    { name: "Qatar", code: "+974", iso: "QA", flag: "qa.svg" },
    { name: "Réunion", code: "+262", iso: "RE", flag: "re.svg" },
    { name: "Romania", code: "+40", iso: "RO", flag: "ro.svg" },
    { name: "Russia", code: "+7", iso: "RU", flag: "ru.svg" },
    { name: "Rwanda", code: "+250", iso: "RW", flag: "rw.svg" },
    { name: "Saint Barthélemy", code: "+590", iso: "BL", flag: "bl.svg" },
    { name: "Saint Helena", code: "+290", iso: "SH", flag: "sh.svg" },
    {
      name: "Saint Kitts and Nevis",
      code: "+1 869",
      iso: "KN",
      flag: "kn.svg",
    },
    { name: "Saint Lucia", code: "+1 758", iso: "LC", flag: "lc.svg" },
    { name: "Saint Martin", code: "+590", iso: "MF", flag: "mf.svg" },
    {
      name: "Saint Pierre and Miquelon",
      code: "+508",
      iso: "PM",
      flag: "pm.svg",
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "+1 784",
      iso: "VC",
      flag: "vc.svg",
    },
    { name: "Samoa", code: "+685", iso: "WS", flag: "ws.svg" },
    { name: "San Marino", code: "+378", iso: "SM", flag: "sm.svg" },
    { name: "Sao Tome and Principe", code: "+239", iso: "ST", flag: "st.svg" },
    { name: "Saudi Arabia", code: "+966", iso: "SA", flag: "sa.svg" },
    { name: "Senegal", code: "+221", iso: "SN", flag: "sn.svg" },
    { name: "Serbia", code: "+381", iso: "RS", flag: "rs.svg" },
    { name: "Seychelles", code: "+248", iso: "SC", flag: "sc.svg" },
    { name: "Sierra Leone", code: "+232", iso: "SL", flag: "sl.svg" },
    { name: "Singapore", code: "+65", iso: "SG", flag: "sg.svg" },
    { name: "Slovakia", code: "+421", iso: "SK", flag: "sk.svg" },
    { name: "Slovenia", code: "+386", iso: "SI", flag: "si.svg" },
    { name: "Solomon Islands", code: "+677", iso: "SB", flag: "sb.svg" },
    { name: "Somalia", code: "+252", iso: "SO", flag: "so.svg" },
    { name: "South Africa", code: "+27", iso: "ZA", flag: "za.svg" },
    { name: "South Sudan", code: "+211", iso: "SS", flag: "ss.svg" },
    { name: "Spain", code: "+34", iso: "ES", flag: "es.svg" },
    { name: "Sri Lanka", code: "+94", iso: "LK", flag: "lk.svg" },
    { name: "Sudan", code: "+249", iso: "SD", flag: "sd.svg" },
    { name: "Suriname", code: "+597", iso: "SR", flag: "sr.svg" },
    { name: "Sweden", code: "+46", iso: "SE", flag: "se.svg" },
    { name: "Switzerland", code: "+41", iso: "CH", flag: "ch.svg" },
    { name: "Syria", code: "+963", iso: "SY", flag: "sy.svg" },
    { name: "Taiwan", code: "+886", iso: "TW", flag: "tw.svg" },
    { name: "Tajikistan", code: "+992", iso: "TJ", flag: "tj.svg" },
    { name: "Tanzania", code: "+255", iso: "TZ", flag: "tz.svg" },
    { name: "Thailand", code: "+66", iso: "TH", flag: "th.svg" },
    { name: "Timor-Leste", code: "+670", iso: "TL", flag: "tl.svg" },
    { name: "Togo", code: "+228", iso: "TG", flag: "tg.svg" },
    { name: "Tokelau", code: "+690", iso: "TK", flag: "tk.svg" },
    { name: "Tonga", code: "+676", iso: "TO", flag: "to.svg" },
    { name: "Trinidad and Tobago", code: "+1 868", iso: "TT", flag: "tt.svg" },
    { name: "Tunisia", code: "+216", iso: "TN", flag: "tn.svg" },
    { name: "Turkey", code: "+90", iso: "TR", flag: "tr.svg" },
    { name: "Turkmenistan", code: "+993", iso: "TM", flag: "tm.svg" },
    {
      name: "Turks and Caicos Islands",
      code: "+1 649",
      iso: "TC",
      flag: "tc.svg",
    },
    { name: "Tuvalu", code: "+688", iso: "TV", flag: "tv.svg" },
    { name: "Uganda", code: "+256", iso: "UG", flag: "ug.svg" },
    { name: "Ukraine", code: "+380", iso: "UA", flag: "ua.svg" },
    { name: "United Arab Emirates", code: "+971", iso: "AE", flag: "ae.svg" },
    { name: "United Kingdom", code: "+44", iso: "GB", flag: "gb.svg" },
    { name: "United States", code: "+1", iso: "US", flag: "us.svg" },
    { name: "Uruguay", code: "+598", iso: "UY", flag: "uy.svg" },
    { name: "Uzbekistan", code: "+998", iso: "UZ", flag: "uz.svg" },
    { name: "Vanuatu", code: "+678", iso: "VU", flag: "vu.svg" },
    { name: "Vatican City", code: "+379", iso: "VA", flag: "va.svg" },
    { name: "Venezuela", code: "+58", iso: "VE", flag: "ve.svg" },
    { name: "Vietnam", code: "+84", iso: "VN", flag: "vn.svg" },
    { name: "Wallis and Futuna", code: "+681", iso: "WF", flag: "wf.svg" },
    { name: "Western Sahara", code: "+212", iso: "EH", flag: "eh.svg" },
    { name: "Yemen", code: "+967", iso: "YE", flag: "ye.svg" },
    { name: "Zambia", code: "+260", iso: "ZM", flag: "ZM", flag: "zm.svg" },
    { name: "Zimbabwe", code: "+263", iso: "ZW", flag: "zw.svg" },
  ];

  const filteredCountries = countriesData?.filter((country) => {
    return country.code == country_code; // Use strict equality (===) for comparison
  });
  return filteredCountries[0].iso;
  console.log(filteredCountries[0].iso, "filteredCountriesfilteredCountries");
}
