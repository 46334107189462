import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "../Component/Pagination";
import ReactStars from "react-rating-stars-component";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  amountdistribute,
  bookinlist,
  cancelbookingrequest,
  cancelreason,
  exportbooking,
  refund,
  updatebookingcarer,
} from "../Redux/Action/BookingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FirstLetterCapital from "../CustomHooks/FirstLetterCapital";
export default function EndTrip() {
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setshow] = useState(false);
  const [id, setid] = useState("");
  const [userId, setuserId] = useState("");
  const [page, setPage] = useState(1);
  const [subtotal, setSubTotal] = useState("");
  const [search, setSearch] = useState("");
  const [canclerequestshow, setCancleRequestShow] = useState(false);
  const [booking_number, setBookingnumber] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      bookinlist({
        search: "",
        status: "completed",
        page: 1,
        limit: 15,
      })
    );
    dispatch(cancelreason());
  }, []);
  const list = useSelector((state) => state.Booking.booking);

  //resasonlist
  const handleClose = () => {
    setshow(false);
    setid("");
  };

  //updatebookingcarer

  const handelsearch = () => {
    if (search != "") {
      dispatch(
        bookinlist({
          search: search,
          status: "completed",
          page: page,
          limit: 10,
        })
      );
    } else {
      dispatch(
        bookinlist({
          search: "",
          status: "completed",
          page: page,
          limit: 10,
        })
      );
    }
  };

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dispatch(
      bookinlist({
        search: "",
        status: "completed",
        page: e.selected + 1,
        limit: 10,
      })
    );
  };

  //refund
  const formik = useFormik({
    initialValues: {
      balance: "",
    },
    validationSchema: Yup.object().shape({
      balance: Yup.number()
        .required("Amount is required")
        .test(
          "is-less-than-subtotal",
          "Balance should be less than or equal to the subtotal",
          function (value) {
            return value <= subtotal; // Ensure balance is less than subtotal
          }
        ),
    }),
    onSubmit: (value, { setSubmitting, resetForm }) => {
      value.currency = "USD";
      value.booking_id = id;
      value.userId = userId;
      value.booking_number = booking_number;

      dispatch(amountdistribute(value)).then(function () {
        setshow(false);
        formik.resetForm();
        dispatch(
          bookinlist({
            search: "",
            status: "completed",
            page: 1,
            limit: 15,
          })
        );
        setSubmitting(false);
      });
    },
  });
  return (
    <Layout>
      <LoadingOverlay text="Loading data...">
        {" "}
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>

                <input
                  type="text"
                  placeholder="Search booking id..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button class="search-button" onClick={handelsearch}>
                  Search
                </button>
              </div>
            </div>
            <div class="user_list_wrap table-responsive">
              <table class="table table-stripd">
                <thead>
                  <th>Booking Number</th>

                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Rating</th>
                  <th>Feedback</th>
                  <th>Picture</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {list?.data?.map(function (object, i) {
                    return (
                      <tr key={i}>
                        <td>{object?.booking_number}</td>

                        <td>
                          <div className=" align-items-center nameimgbcx">
                            <img
                              src={
                                object?.carer?.profile_picture == undefined
                                  ? "https://i.imghippo.com/files/tE3170G.webp"
                                  : object?.carer?.profile_picture
                              }
                              alt="profile"
                              width="31.33"
                              height="31.33"
                            />{" "}
                            <span className="namebcx">
                              {" "}
                              {FirstLetterCapital(
                                object?.carer?.first_name +
                                  " " +
                                  object?.carer?.last_name
                              )}
                            </span>
                          </div>
                        </td>
                        <td>{object?.carer?.email}</td>
                        <td>
                          {object?.carer?.phone?.country_code == undefined ? (
                            "N/A"
                          ) : (
                            <>
                              <Flag
                                country={Contry(
                                  object?.carer?.phone?.country_code
                                )}
                                size={20}
                              />{" "}
                              {object?.carer?.phone?.country_code +
                                " " +
                                object?.carer?.phone?.number}
                            </>
                          )}{" "}
                        </td>
                        <td>
                          {object?.payment_details?.subtotal.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                        </td>
                        <td>
                          {" "}
                          <ReactStars
                            count={5} // Number of stars
                            size={30} // Size of stars
                            isHalf={true} // Allow half-star ratings
                            activeColor="#ffd700" // Color of active stars
                            value={object?.review?.rating} // Current rating value
                            edit={false}
                          />
                        </td>
                        <td>
                          <div data-tooltip-id={"my-tooltip-" + i}>
                            {object?.review?.feedback == ""
                              ? "N/A"
                              : object?.review?.feedback}
                          </div>
                          <ReactTooltip
                            id={"my-tooltip-" + i}
                            place="bottom"
                            content={
                              object?.review?.feedback == ""
                                ? "N/A"
                                : object?.review?.feedback
                            }
                          />
                        </td>
                        <td>
                          {object?.review?.picture != undefined ? (
                            <img
                              src={object?.review?.picture}
                              alt="No Image"
                              width="31.33"
                              height="31.33"
                              onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                              }}
                            />
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>
                          {" "}
                          <div className="iconsbcx">
                            <div className="checkbxt">
                              <div
                                className="common-btn-assign distribute"
                                onClick={() => {
                                  setshow(true);
                                  setid(object?._id);
                                  setuserId(object.carer?._id);
                                  setBookingnumber(object?.booking_number);
                                  setSubTotal(
                                    object?.payment_details?.subtotal
                                  );
                                }}
                              >
                                Pay Now
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={list?.totalItems}
              />
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Care Amount</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="adminAmount" className="mb-2">
                <Form.Label>Sub Total</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter amount for carer"
                  value={subtotal}
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="adminAmount">
                <Form.Label>Carer Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter amount for carer"
                  name="balance"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.balance}
                />

                {formik.touched.balance &&
                  formik.submitCount > 0 &&
                  formik.errors.balance && (
                    <div className="error">{formik.errors.balance}</div>
                  )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {/* Cancel Button */}

              <Button
                variant="danger"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {formik.isSubmitting ? "Processing..." : "Pay"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </LoadingOverlay>
    </Layout>
  );
}
