import axios from "axios";
console.log(process.env.REACT_APP_URL);

const Api = axios.create({
  baseURL: process.env.REACT_APP_URL,

  headers: { authorization: localStorage.getItem("authorization") },
});

// Example of allowing certain status codes in the success handler
Api.interceptors.response.use(
  function (response) {
    // Handle successful responses (status codes 2xx)
    return response;
  },
  function (error) {
    // For other error statuses, reject as usual
    return Promise.reject(error);
  }
);

export default Api;
