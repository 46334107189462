import { createSlice } from "@reduxjs/toolkit";
import { GetPromotion } from "../Action/PromoCode";
import { bookinlist, getbookingdetail } from "../Action/BookingAction";
import { getcommission } from "../Action/Commission";
import { totalTransactions } from "../Action/TransactionAction";

const TransactionSlice = createSlice({
  name: "Transaction",
  initialState: {
    total_transaction: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalTransactions.fulfilled, (state, action) => {
      state.total_transaction = action.payload;
    });
  },
});

export default TransactionSlice.reducer;
