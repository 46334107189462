import React, { useState } from "react";
import logo from "../assets/images/Buddy-4-Travel_logo.png";
import Sidebar from "./Sidebar";
import usericon from "../assets/images/admin_user_pic.jpg";
import bell from "../assets/images/bell.png";

export default function Layout(props) {
  const [toggle, setToggle] = useState("");
  console.log(toggle);
  return (
    <div className={toggle}>
      <div className="side_bar">
        <a href="javascript:void(0)" className="btn-close-menu">
          <i className="fa fa-times" aria-hidden="true"></i>
        </a>
        <div className="logo_wrap">
          <a href="#">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="sidebar_menu_wrap">
          <Sidebar />
        </div>
      </div>
      <div className="dashboard_header_wrap">
        <div className="header_wrapper">
          <div
            className="toggle_wrap"
            onClick={() => {
              setToggle(
                toggle == "toggle-hide-unhide" ? "" : "toggle-hide-unhide"
              );
            }}
          >
            <a href="javascript:void(0)" className="atab-menumb">
              <i className="fa fa-bars tab-menu" aria-hidden="true"></i>
            </a>
          </div>
          <div
            className="user_detail_wrap"
            aria-labelledby="dropdownMenuButton"
          >
            <div className="notiofy">
              <i className="fa fa-bell-o" aria-hidden="true"></i>
            </div>
            <div className="admin-tag">Admin</div>
            <div class="dropdown">
              <a
                class="waves-effect dropdown-toggle"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={usericon} alt="" />

                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = "/";
                    }}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}
