import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getDetails } from "../Redux/Action/SubAdmin";

export default function Sidebar() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetails());
  }, []);
  const admindetails = useSelector((state) => state.SubAdmin.admindetails);
  return (
    <div class="sidebar_menu">
      <ul>
        <ul>
          {admindetails?.permission?.map((object, i) => (
            <li key={i}>
              <NavLink to={object.url}>
                <i className={object.icon}></i>
                <span className="text-side-bar">{object.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </ul>
    </div>
  );
}
