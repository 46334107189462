import React from "react";
import Layout from "../layout/Layout";
import user from "../assets/images/userimg-bg.png";
import sendBtn from "../assets/images/sendbtn.png";
export default function Chat() {
  return (
    <Layout>
      <div className="dashboard_data_wrap">
        <div className="dashbord_cont_wrapper">
          <div class="chat-container">
            <div className="row">
              <div className="col-md-4">
                <div className="chatusers-listbcx">
              <div className="search-container">
              <div className="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Search here..."
                />
                {/* <button className="search-button">
                  Search
                </button> */}
              </div>
            </div>
            <div className="listbcx-wrap">
            <ul>
                    <li>
                      <div className="chatusers d-flex align-items-center">
                        <div className="userinfo d-flex align-items-center">
                          <div className="userimg">
                            <img src={user} alt="" />
                          </div>
                          <p>Gaurav T</p>
                        </div>
                        <div className="chattime">
                          <span>11:00 AM</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="chatusers d-flex align-items-center">
                        <div className="userinfo d-flex align-items-center">
                          <div className="userimg">
                            <img src={user} alt="" />
                          </div>
                          <p>Gaurav T</p>
                        </div>
                        <div className="chattime">
                          <span>11:00 AM</span>
                        </div>
                      </div>
                    </li>
                  </ul>
            </div>
                </div>
              </div>
              <div className="col-md-8">
              <div class="chat-header">
              <div className="chatadminuser">
                <div className="userimg">
                  <img src={user} alt="" />
                </div>
                <div className="userinfo">
                  <p>Gaurav T</p>
                </div>
              </div>
            </div>

            <div class="chat-messages" id="chatMessages">
              <div className="message received">
                <div className="chturmg">
                  <img src={user} alt=""/>
                </div>
                <div className="msgbcx">
                Good Morning
                <div className="messagetim">
                  01:40 AM
                </div>
                </div>
              </div>
              <div className="message sent">
                
                <div className="msgbcx">
                Good Morning
                <div className="messagetim">
                  01:40 AM
                </div>
                </div>
                <div className="chturmg">
                  <img src={user} alt=""/>
                </div>
              </div>
            </div>

            <div class="chat-footer">
              <input
                type="text"
                id="messageInput"
                placeholder="Type a message..."
              />
              <label for="fileInput" class="file-label">
                📎
                <input type="file" id="fileInput" class="file-input" />
              </label>
              <button onclick="sendMessage()">
                <img src={sendBtn} />
              </button>
            </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </Layout>
  );
}
