import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  wificreate,
  wifidelete,
  wifilist,
  wifiupdate,
} from "../Redux/Action/WifiAction";
import Pagination from "../Component/Pagination";
import LoadingOverlay from "react-loading-overlay";
import DeleteModal from "../Component/DeleteModal";

export default function WifiConnection() {
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [id, setid] = useState("");

  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true); // Loader state
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const handleClose = () => {
    setShow(false);
    setid("");
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      wifi_name: "",
      password: "",
      locationname: "",
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object().shape({
      wifi_name: Yup.string().required("Wifiname is required"),
      password: Yup.string().required("Password is required"),
      locationname: Yup.string().required("Location is required"),
      lat: Yup.string().required("Location is required"),
      lng: Yup.string().required("Location is required"),
    }),
    onSubmit: (value) => {
      setLoading(true);
      if (id != "") {
        value.id = id;
        dispatch(wifiupdate(value)).then(function () {
          dispatch(wifilist(limit, page)).then(() => setLoading(false));
          setid("");
        });
      } else {
        dispatch(wificreate(value)).then(function () {
          dispatch(wifilist(limit, page)).then(() => setLoading(false));
        });
      }

      formik.resetForm();
      setShow(false);
    },
  });

  const handlePlaceSelected = (place) => {
    // You can log or process the selected place here
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    // Set the location value in Formik
    formik.setFieldValue("lat", lat); // Using lat
    formik.setFieldValue("lng", lng); // Using ln
    formik.setFieldValue("locationname", place.formatted_address || place.name); // Using formatted_address or name
  };

  useEffect(() => {
    dispatch(wifilist(limit, page)).then(() => setLoading(false));
  }, []);
  const list = useSelector((state) => state.Wifi.wifiArr);
  const handlePageClick = (e) => {
    setPage(e.selected);
    setLoading(true);
    dispatch(wifilist({ limit, page: e.selected + 1 })).then(() =>
      setLoading(false)
    );
  };
  const handleDelete = () => {
    dispatch(wifidelete(id)).then(function () {
      setDeleteShow(false);
      dispatch(
        wifilist({
          limit,
          page,
        })
      ).then(() => setLoading(false));
    });
    setid("");
  };
  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        {" "}
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box"></div>
              <div class="export-options">
                <button
                  className="common-btn"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  +Add
                </button>
                <button className="ml-2 common-btn">Save</button>
              </div>
            </div>
            <div class="user_list_wrap table-responsive">
              <table class="table table-stripd">
                <thead>
                  <th> WiFi Name </th>
                  <th>Location</th>
                  <th>Long</th>
                  <th>Lat</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {list?.wifi?.map(function (object, i) {
                    return (
                      <tr key={i}>
                        <td>
                          <div>
                            {" "}
                            <i className="fas fa-wifi mr-2"></i>
                            {object.wifi_name}
                            <span>
                              {object.isActive == false ? "Enable" : "Disable"}
                            </span>
                          </div>
                        </td>
                        <td>{object?.locationname}</td>
                        <td>{object.location?.coordinates[0]}</td>
                        <td>{object.location?.coordinates[1]}</td>
                        <td>
                          <div className="action-btn">
                            <i
                              className="fas fa-pencil"
                              onClick={() => {
                                formik.setFieldValue(
                                  "wifi_name",
                                  object.wifi_name
                                );
                                formik.setFieldValue(
                                  "lat",
                                  object.location?.coordinates[0]
                                );
                                formik.setFieldValue(
                                  "lng",
                                  object.location?.coordinates[1]
                                );
                                formik.setFieldValue(
                                  "locationname",
                                  object.location?.locationname
                                );
                                formik.setFieldValue(
                                  "password",
                                  object.password
                                );
                                setShow(true);
                                setid(object?._id);
                              }}
                            ></i>
                            <i className="fas fa-eye"></i>
                            <i
                              className="fas fa-trash"
                              onClick={() => {
                                setid(object?._id);
                                setDeleteShow(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={list?.totalItems}
              />
              <DeleteModal
                show={deleteShow}
                handleClose={() => {
                  setDeleteShow(false);
                }}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{id != "" ? "Update" : "Add"} Wifi Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Banner Image Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Wifi Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter Wifi Name"
                  name="wifi_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.wifi_name}
                />
                {formik.touched.wifi_name &&
                  formik.submitCount > 0 &&
                  formik.errors.wifi_name && (
                    <div className="error">{formik.errors.wifi_name}</div>
                  )}
              </div>
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter Wifi password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password &&
                  formik.submitCount > 0 &&
                  formik.errors.password && (
                    <div className="error">{formik.errors.password}</div>
                  )}
              </div>
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Location
                </label>
                <Autocomplete
                  apiKey={process.env.REACT_APP_APIKEY}
                  onPlaceSelected={handlePlaceSelected}
                  options={{
                    types: ["establishment"], // Restrict results to establishments
                    componentRestrictions: { country: ["IND"] }, // Optional: Limit to a specific country
                  }}
                />
                {formik.touched.location && formik.errors.location && (
                  <div className="error">{formik.errors.location}</div>
                )}
              </div>
              <div className="modal-footer">
                {/* Close Button */}

                {/* Submit Button */}
                <button type="submit" className="common-btn">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Layout>
    </LoadingOverlay>
  );
}
