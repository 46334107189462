import React from "react";
import logo from "../assets/images/Buddy-4-Travel_logo.png";
import logo1 from "../assets/images/Food_1.png";
import logo2 from "../assets/images/Food_2.jpg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import logo3 from "../assets/images/Group 418323.png";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../Redux/Action/AuthAction";
export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  localStorage.setItem("authorization", token);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmed_password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
      confirmed_password: Yup.string()
        .required("Confirmed Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match"),
    }),
    onSubmit: (value) => {
      value.token = token;
      dispatch(resetPassword(value)).then(function () {
        navigate("/");
      });
    },
  });

  return (
    <div className="background loginpage-wrap">
      <img src={logo} alt="logo" height="40" />
      <div className="right-section" style={{ alignItems: "center" }}>
        <h1>Reset new password</h1>

        <form className="login-form" onSubmit={formik.handleSubmit}>
          <div className="forgot-password">
            <div className="form-group">
              <input
                type="password"
                placeholder="New Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                name="password"
              />
              {formik.touched.password &&
                formik.submitCount > 0 &&
                formik.errors.password && (
                  <div className="error">{formik.errors.password}</div>
                )}
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Confirmed Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmed_password}
                name="confirmed_password"
              />
              {formik.touched.confirmed_password &&
                formik.submitCount > 0 &&
                formik.errors.confirmed_password && (
                  <div className="error">
                    {formik.errors.confirmed_password}
                  </div>
                )}
            </div>
          </div>
          <button type="submit">Reset password</button>
        </form>
        <img className="shape" src={logo3} alt="Decorative Shape" />
      </div>
    </div>
  );
}
